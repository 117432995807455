import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody, checkSuccess } from 'utils/requests';

export async function getCompanyInfo(): Promise<CompanyData> {
  const response = await fetchWithToken('/company_info');

  const data = await response.json();

  checkSuccess(data);

  return {
    companyName: data.company_name,
    companyMlsId: data.company_mls_id,
    companyAddress: data.company_address1,
    companyAddress2: data.company_address2,
    companyCity: data.company_city,
    companyState: data.company_state,
    companyZip: data.company_zip,
    companyStateLicenseId: data.company_state_license_id,
    sponsoring_broker_fee: data.sponsoring_broker_fee,
    technology_fee: data.technology_fee,
    desk_fee: data.desk_fee,
    sell_agreements_fee: data.sell_agreements_fee,
    lease_agreements_fee: data.lease_agreements_fee,
    transaction_coordination_fee: data.transaction_coordination_fee,
    risk_management_fee: data.risk_management_fee
  };
}

export async function updateCompanyInfo(values: Partial<CompanyData>): Promise<void> {
  const body = urlEncodeBody({
    company_name: values.companyName,
    company_mls_id: values.companyMlsId,
    company_address1: values.companyAddress,
    company_address2: values.companyAddress2,
    company_city: values.companyCity,
    company_state: values.companyState,
    company_zip: values.companyZip,
    company_state_license_id: values.companyStateLicenseId,
    sponsoring_broker_fee: values.sponsoring_broker_fee,
    technology_fee: values.technology_fee,
    desk_fee: values.desk_fee,
    sell_agreements_fee: values.sell_agreements_fee,
    lease_agreements_fee: values.lease_agreements_fee,
    transaction_coordination_fee: values.transaction_coordination_fee,
    risk_management_fee: values.risk_management_fee
  });
  await fetchWithToken('/company_info', { method: 'PATCH', body });
}
